import React, { createContext, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { parseAwards } from 'utils/awards';
import { objectToArray } from 'utils/array';
import {
  getRegionTitleByCountry,
  getCountryNameByCode,
  getCountryCodeByName,
} from 'utils/territories';
import { getMovieData, getImage } from 'api/film';
import { REACT_APP_API_URL } from 'config';
import CountryData from 'country-data';
import defaultFilmData from './defaultFilmData';

const initialState = {
  oldData: {},
  data: {},
  setInitialData: () => {},
  update: () => {},
  imageUrl: null,
  setImageUrl: () => {},
  imageForNewFilm: null,
  setImageForNewFilm: () => {},
  fetchFilmData: async () => {},
  isContainNoneEnglish: false,
  setIsContainNonEnglish: () => {},
  isReleased: false,
  setIsReleased: () => {},
  isTaxIncentives: false,
  setIsTaxIncentives: () => {},
  isFestivals: false,
  setIsFestivals: () => {},
  isStemsFrom: false,
  setIsStemsFrom: () => {},
  istTheatricalExhibition: false,
  setIsTheatricalExhibition: () => {},
  isBasedOn: false,
  setIsBasedOn: () => {},
  isDevelopmentDeal: false,
  setIsDevelopmentDeal: () => {},
  prepareToSave: () => {},
  validate: () => {},
  prepareDistributorsToSave: () => {},
  prepareReleaseDateToSave: () => {},
  transformMovieDataToInitialData: () => {},
  getDistributorsSplitByRegion: () => {},
  prepareAwardsToSave: () => {},
  prepareFinancialsToSave: () => {},
};

const FilmDetailsContext = createContext(initialState);

const FilmDetailsProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [updatedData, setUpdatedData] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageForNewFilm, setImageForNewFilm] = useState(null);

  // toggles
  const [isContainNoneEnglish, setIsContainNonEnglish] = useState(false);
  const [isReleased, setIsReleased] = useState(false);
  const [isTaxIncentives, setIsTaxIncentives] = useState(false);
  const [isFestivals, setIsFestivals] = useState(false);
  const [isStemsFrom, setIsStemsFrom] = useState(false);
  const [istTheatricalExhibition, setIsTheatricalExhibition] = useState(false);
  const [isBasedOn, setIsBasedOn] = useState(false);
  const [isDevelopmentDeal, setIsDevelopmentDeal] = useState(false);

  const prepareDistributorsToSave = (distributors) => {
    let distributorsToSave = [];

    if (!distributors) return distributorsToSave;

    if (Array.isArray(distributors)) {
      distributorsToSave = [...distributors];
    } else {
      const distributorsNotSplitByRegion = objectToArray(distributors);

      distributorsNotSplitByRegion.forEach((distributor) => {
        const countryCode = getCountryCodeByName(distributor.country);

        if (countryCode) {
          distributorsToSave.push({
            ...distributor,
            country: countryCode,
          });
        } else {
          distributorsToSave.push({
            ...distributor,
          });
        }
      });
    }

    return distributorsToSave;
  };

  const prepareAwardsToSave = (awards) => {
    let awardsToSave = [];

    if (!awards) return awardsToSave;

    const prepareAwardToSave = (award) => {
      const awardToSave = { ...award };

      if (awardToSave.recipients) {
        if (
          !Array.isArray(awardToSave.recipients) &&
          typeof awardToSave.recipients === 'string'
        ) {
          awardToSave.recipients = awardToSave.recipients
            .split(',')
            .map((item) => item.trim());
        }
      } else {
        awardToSave.recipients = [];
      }

      return awardToSave;
    };

    if (Array.isArray(awards)) {
      awardsToSave = awards.map(prepareAwardToSave);
    } else {
      awardsToSave = objectToArray(awards).map(prepareAwardToSave);
    }

    return awardsToSave;
  };

  const prepareFinancialsToSave = (financials) => ({
    ...financials,
    domestic_home_ent_margin: financials?.domestic_home_ent_margin?.default
      ? null
      : financials?.domestic_home_ent_margin?.value,
    domestic_tv_streaming: financials?.domestic_tv_streaming?.default
      ? null
      : financials?.domestic_tv_streaming?.value,
  });

  const prepareReleaseDateToSave = (releaseDate) => {
    const releaseDateToSave = [];

    if (!releaseDate || !releaseDate.length) return releaseDateToSave;

    releaseDate.forEach((item) => {
      if (item.country) {
        const countryCode = getCountryCodeByName(
          item.country === 'Worldwide' ? 'WORLDWIDE' : item.country,
        );

        if (countryCode) {
          releaseDateToSave.push({
            ...item,
            country: countryCode,
          });
        }
      } else {
        releaseDateToSave.push({
          ...item,
        });
      }
    });

    return releaseDateToSave;
  };

  const prepareToSave = useCallback(
    (info, isFile) => ({
      ...info,
      awards: Array.isArray(info.awards)
        ? info.awards
        : objectToArray(info.awards),
      downloadables: defaultFilmData.downloadables,
      non_english_dialogues:
        (isContainNoneEnglish || isFile) && info.non_english_dialogues?.length
          ? info.non_english_dialogues
          : [],
      release_date: info.release_date?.length ? info.release_date : [],
      financials:
        isTaxIncentives || isFile || info.financials?.tax_incentives
          ? {
              ...info.financials,
            }
          : {
              ...info.financials,
              tax_incentives: null,
            },
      festivals:
        (isFestivals || isFile) && info.festivals?.length ? info.festivals : [],
      stems_from:
        (isStemsFrom || isFile) && info.stems_from?.length
          ? info.stems_from
          : [],
      theatrical_exhibition:
        (istTheatricalExhibition || isFile) &&
        info.theatrical_exhibition?.length
          ? info.theatrical_exhibition
          : [],
      based_on:
        (isBasedOn || isFile) && info.based_on?.length ? info.based_on : [],
      development_deal:
        (isDevelopmentDeal || isFile) && info.development_deal
          ? info.development_deal
          : null,
      official_pages: info.official_pages?.filter((page) => page.length) ?? [],
    }),
    [
      isBasedOn,
      isContainNoneEnglish,
      isDevelopmentDeal,
      isFestivals,
      isStemsFrom,
      isTaxIncentives,
      istTheatricalExhibition,
    ],
  );

  const validate = (info) => {
    const needToBeFilled = [
      'title',
      'summary',
      'budget',
      'directors',
      'producers',
      'writers',
      'actors',
      'script',
    ];
    const filled = [];

    const requiredFields = [
      'title',
      'summary',
      'financials',
      'downloadables',
      'cast',
    ];

    for (let i = 0; i < requiredFields.length; i += 1) {
      const field = requiredFields[i];

      switch (field) {
        case 'financials':
          if (
            info[field].production_budget &&
            info[field].marketing_budget &&
            !filled.find((item) => item === 'budget')
          ) {
            filled.push('budget');
          }
          break;
        case 'downloadables':
          if (info[field].script.length > 0) filled.push('script');
          if (
            (info[field].production_budget.length > 0 ||
              info[field].marketing_budget.length > 0) &&
            !filled.find((item) => item === 'budget')
          ) {
            filled.push('budget');
          }
          break;
        case 'cast':
          if (info[field].directors.length > 0) filled.push('directors');
          if (info[field].producers.length > 0) filled.push('producers');
          if (info[field].writers.length > 0) filled.push('writers');
          if (info[field].actors.length > 0) filled.push('actors');
          break;
        default:
          if (info[field]) {
            filled.push(field);
          }
          break;
      }
    }

    return { valid: filled.length >= 3, needToBeFilled, filled };
  };

  const setInitialData = useCallback((initialData) => {
    const workingData = initialData || defaultFilmData;
    const {
      non_english_dialogues,
      release_date,
      financials,
      festivals,
      stems_from,
      theatrical_exhibition,
      based_on,
      development_deal,
    } = workingData;

    let awardsToShow = [];

    if (workingData.awards) awardsToShow = [...workingData.awards];

    if (workingData.awards_nominated)
      awardsToShow = [
        ...awardsToShow,
        ...workingData.awards_nominated.map((item) => ({
          ...item,
          status: 'nominated',
        })),
      ];

    const preparedToShow = {
      ...workingData,
      awards: parseAwards(awardsToShow),
    };

    if (release_date) {
      setIsReleased(
        release_date.some((rd) => new Date(rd.date).getTime() < Date.now()),
      );
    }

    setIsContainNonEnglish(
      Array.isArray(non_english_dialogues)
        ? non_english_dialogues.length > 0
        : Boolean(non_english_dialogues),
    );

    setData({ ...preparedToShow });
    setUpdatedData({ ...preparedToShow });
    setImageForNewFilm(null);

    setIsTaxIncentives(Boolean(financials?.tax_incentives));
    setIsFestivals(festivals?.length > 0);
    setIsStemsFrom(stems_from?.length > 0);
    setIsTheatricalExhibition(theatrical_exhibition?.length > 0);
    setIsBasedOn(based_on?.length > 0);
    setIsDevelopmentDeal(Boolean(development_deal));
  }, []);

  const update = useCallback(
    (newData) => {
      const prevData = updatedData || {};
      setUpdatedData({ ...prevData, ...newData });
    },
    [updatedData],
  );

  const getDistributorsSplitByRegion = (distributors) => {
    const distributorsSplitByRegion = {
      Worldwide: [],
      'North America': [],
      UK: [],
      Europe: [],
      'Far East': [],
      'Latin America': [],
      'Central America': [],
      'Middle East': [],
      'Eastern Europe': [],
    };

    if (distributors.length) {
      distributors.forEach((distributor) => {
        const countryName = getCountryNameByCode(distributor.country);

        if (countryName) {
          const regionTitle = getRegionTitleByCountry(countryName);

          if (regionTitle)
            distributorsSplitByRegion[regionTitle].push({
              ...distributor,
              country: countryName,
            });
        } else {
          distributorsSplitByRegion.Worldwide.push({
            ...distributor,
          });
        }
      });
    }

    return distributorsSplitByRegion;
  };

  const transformMovieDataToInitialData = (
    movieData,
    tryParseAwards = true,
  ) => {
    const transformedData = { ...movieData };

    if (Array.isArray(movieData.distributors)) {
      const distributorsSplitByRegion = getDistributorsSplitByRegion(
        movieData.distributors,
      );

      transformedData.distributors = distributorsSplitByRegion;
    }

    if (movieData.release_date?.length) {
      const releaseDateWithCountryName = movieData.release_date.map((item) => ({
        ...item,
        country: getCountryNameByCode(item.country) ?? item.country,
      }));

      transformedData.release_date = releaseDateWithCountryName;
    }

    if (tryParseAwards) {
      let awardsToParse = [];

      if (transformedData.awards) awardsToParse = [...transformedData.awards];

      if (transformedData.awards_nominated)
        awardsToParse = [
          ...awardsToParse,
          ...transformedData.awards_nominated.map((item) => ({
            ...item,
            status: 'nominated',
          })),
        ];

      transformedData.awards = parseAwards(awardsToParse);
    }

    return transformedData;
  };

  const fetchFilmData = useCallback(
    async (id) => {
      if (id) {
        const res = await getMovieData(id);
        const image = await getImage(id);
        if (image) {
          setImageUrl(`${REACT_APP_API_URL}/poster/${id}`);
        }
        const transformedMovieData = transformMovieDataToInitialData(
          res,
          false,
        );

        setInitialData({ ...transformedMovieData });
      } else {
        setInitialData(null);
      }
    },
    [setInitialData],
  );

  const filmDetails = {
    oldData: data,
    data: updatedData,
    setInitialData,
    update,
    imageUrl,
    setImageUrl,
    imageForNewFilm,
    setImageForNewFilm,
    fetchFilmData,
    isContainNoneEnglish,
    isReleased,
    isTaxIncentives,
    setIsTaxIncentives,
    isFestivals,
    setIsFestivals,
    isStemsFrom,
    setIsStemsFrom,
    istTheatricalExhibition,
    setIsTheatricalExhibition,
    isBasedOn,
    setIsBasedOn,
    isDevelopmentDeal,
    setIsDevelopmentDeal,
    prepareToSave,
    validate,
    prepareDistributorsToSave,
    prepareReleaseDateToSave,
    transformMovieDataToInitialData,
    getDistributorsSplitByRegion,
    prepareAwardsToSave,
    prepareFinancialsToSave,
  };

  return (
    <FilmDetailsContext.Provider value={filmDetails}>
      {children}
    </FilmDetailsContext.Provider>
  );
};

FilmDetailsProvider.propTypes = {
  children: PropTypes.any,
};

const useFilmDetails = () => useContext(FilmDetailsContext);

export { FilmDetailsProvider, useFilmDetails };
