import React from 'react';
import { routes } from 'routes/routes';
import {
  PublicFooterWrapper,
  PublicFooterContainer,
  PublicFooterLinkComponent,
} from './elements';

export const PublicFooter = () => (
  <PublicFooterWrapper light expand="sm">
    <PublicFooterContainer>
      © 2025{' '}
      <PublicFooterLinkComponent
        display="contents"
        href="https://www.cinematicventures.com/"
      >
        Superb Intelligence, LLC
      </PublicFooterLinkComponent>
      . All Rights Reserved
    </PublicFooterContainer>
    <PublicFooterContainer>
      <PublicFooterLinkComponent href={routes.PRIVACY_POLICY}>
        Privacy Policy
      </PublicFooterLinkComponent>
      |
      <PublicFooterLinkComponent href={routes.CONTACT_US}>
        Contact
      </PublicFooterLinkComponent>
    </PublicFooterContainer>
  </PublicFooterWrapper>
);
